.gg-gradient {
    z-index: -5;
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.2035) 60%, rgba(0, 0, 0, 0) 100%);
    img {
        position: fixed;
        &.left-bush {
            width: 100px;
            bottom: 0px;
            left: 0px;
        }
        &.right-bush {
            width: 100px;
            bottom: 0px;
            right: 0px;
        }
    }
}