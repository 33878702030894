.quiz-container {
    grid-column: 2 / 3;
    flex: 1 1 auto;
    position: relative;
    min-width: 300px;
    max-width: 450px;
    margin: 0 auto;
    z-index: 1;
    width: 100%;
    width: calc(100% - 16px);
    padding-left: 8px;
    padding-right: 8px;
    .gg-title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 10px;
        min-height: 73px;
        img {
            width: 100%;
        }
    }
    .item-title {
        color: white;
        text-shadow: 1px 1px 5px black;
        text-align: center;
        font-size: 30px;
        margin-top: 0px;
        margin-bottom: 10px;
    }
    .item-desc-container {
        display: flex;
        flex-direction: row;
        background-color: rgb(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 14px;
        cursor: pointer;
        .item-desc {
            width: 100%;
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 20px;
            color: white;
            text-shadow: 1px 1px 2px black;
            transition: max-height 1s ease-in;
            max-height: 268px;
            overflow: hidden;
            &.hidden {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 30px;
                transition: max-height 1s ease-out;
            }
        }
    }
    .item-img-container {
        display: flex;
        justify-content: center;
        max-height: 400px;
        .item-img {
            max-width: 100%;
            background-color: white;
            padding: 10px;
            border: 7px darkorange solid;
            box-sizing: border-box;
            border-radius: 4px;
            max-height: 400px;
        }
    }
    .price-container {
        color: white;
        display: flex;
        font-size: 60px;
        justify-content: center;
        align-items: center;
        .gg-button {
            margin-left: 10px;
            font-size: 20px;
        }
        .dollar {
            text-shadow: 0px 0px 5px black;
            padding-bottom: 10px;
            width: 35px;
        }
        .win-text {
            font-weight: bold;
            text-shadow: 1px 1px 5px black;
            &.animate {
                animation: pulse-text 2s ease-out;
            }
        }
    }
    .guesses-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 10px;
        .guesses-table {
            border-spacing: 0px;
            font-size: 18px;
            color: white;
            text-shadow: 1px 1px 2px black;
            min-width: 320px;
            .guess-count {
                padding-left: 30px;
            }
            .guess-code {
                //padding-left: 40px;
                text-align: center;
            }
            .guess-text {
                text-align: right;
                padding-right: 30px;
            }
            tr:nth-child(2n-1) {
                background-color: rgba(0, 0, 0, 0.068);
            }
            td {
                padding: 3px;
            }
        }
    }
    .check-price-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .check-price-button {
            margin-left: 14px;
            margin-right: 14px;
        }
        .price-diff {
            font-size: 20px;
            width: 82px;
            text-shadow: 0px 0px 5px black;
            //       opacity: 0;
            &.lower {
                text-align: right;
            }
            &.higher {
                text-align: left;
            }
            &.animate {
                animation: blink-opacity 1.3s ease-in-out;
            }
        }
    }
    .guess-button-subtext {
        display: flex;
        justify-content: center;
        color: rgb(94, 94, 94);
        padding-top: 4px;
        text-align: justify;
    }
    .am-disclosure {
        position: fixed;
        bottom: 0px;
        padding-bottom: 5px;
        color: rgb(94, 94, 94);
    }
    .share-container {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 20px;
        .text-copied {
            color: rgb(94, 94, 94);
            height: 30px;
            min-width: 1px;
        }
        .text-backup {
            color: white;
            background-color: rgb(0, 0, 0, 0.1);
            border-radius: 10px;
            padding: 10px;
            margin-bottom: 14px;
            text-align: left;
        }
        .share-button {
            display: flex;
            align-items: center;
            flex-direction: row;
            span {
                margin-right: 8px;
            }
        }
    }
}

.amazon-iframe {
    width: 120px;
    height: 240px;
}

@keyframes pulse-text {
    0% {
        text-shadow: 1px 1px 20px rgb(223, 255, 42);
        transform: scale(0.25);
    }
    20% {
        text-shadow: 1px 1px 10px rgb(223, 255, 42);
    }
    30% {
        text-shadow: 1px 1px 20px rgb(223, 255, 42);
    }
    50% {
        transform: scale(1);
    }
    100% {
        text-shadow: 1px 1px 5px black;
    }
}

@keyframes blink-opacity {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
        transform: scale(1.2);
    }
    100% {
        opacity: 0;
    }
}