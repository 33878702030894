.gg-button {
    -webkit-user-drag: none;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    line-height: normal;
    user-select: none;
    vertical-align: middle;
    border: transparent;
    border-radius: 4px;
    font-size: 1.1em;
    font-weight: bold;
    padding: 0.5em 1em;
    text-decoration: none;
    background-color: darkorange;
    color: white;
    text-shadow: 1px 1px 2px black;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px;
}

.gg-button.cancel {
    background-color: white;
    color: black;
    font-weight: normal;
}

.gg-button:hover {
    background-image: linear-gradient(transparent, rgba(0, 0, 0, .05) 40%, rgba(0, 0, 0, .1))
}

.gg-button[disabled] {
    background-image: none;
    border: none;
    box-shadow: none;
    cursor: not-allowed;
    opacity: .4;
    pointer-events: none
}