.number-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    cursor: default;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently */
    .action {
        //   margin-top: 117px;
        cursor: pointer;
        margin-left: 5px;
        margin-right: 5px;
        background-color: darkorange;
        border-radius: 4px;
        width: 40px;
        height: 40px;
        font-size: 30px;
        line-height: 34px;
        &:hover {
            background-image: linear-gradient(transparent, rgba(0, 0, 0, .05) 40%, rgba(0, 0, 0, .1))
        }
        &.bottom {
            margin-top: 108px;
        }
    }
    .number {
        margin-top: 50px;
        position: absolute;
        font-weight: 500;
        background-color: #3a3a3a;
        border: 3px black solid;
        border-radius: 5px;
        width: 46px;
        box-sizing: border-box;
        height: 86px;
        display: flex;
        &.animate {
            animation-name: spin-invert-return;
            animation-timing-function: linear;
            animation-duration: 0.3s;
        }
        .number-divider {
            position: absolute;
            top: 39px;
            width: 41px;
            height: 2px;
            background-color: #00000042;
        }
        .number-animation {
            width: 46px;
            position: absolute;
            opacity: 1;
            height: 80px;
        }
        .digit {
            width: 40px;
            text-align: center;
            align-self: center;
        }
    }
}

@keyframes spin {
    from {
        transform: rotateX(0deg);
    }
    to {
        transform: rotateX(-360deg);
    }
}

@keyframes spin-invert-return {
    0% {
        transform: rotateX(0deg);
    }
    50% {
        transform: rotateX(-90deg);
    }
    100% {
        transform: rotateX(0deg);
    }
}