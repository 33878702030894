.landing-page {
    grid-column: 2;
    //  min-height: 1071px;
    //  height: 100vh;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    .monkey-container {
        padding: 5px;
        display: flex;
        max-width: 600px;
        align-items: center;
        flex-direction: column;
    }
    .how-to-play-container {
        text-shadow: 0px 0px 4px black;
        background-color: rgb(0, 0, 0, 0.15);
        border-radius: 10px;
        padding: 10px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 14px;
    }
    .how-to-play-title {
        font-size: 28px;
        margin-bottom: 0px;
        margin-top: 2px;
        text-align: center;
        text-decoration: underline;
    }
    .how-to-play-body {
        font-size: 20px;
    }
    .guesses-key-table {
        margin: auto;
    }
}